import Link from "next/link";

export default function AlertBanner() {
  return (
    <div className="relative isolate flex items-center justify-center gap-x-6 overflow-hidden bg-[#298458] px-6 py-2.5 sm:px-3.5">
      <div className="flex flex-wrap gap-y-2 gap-x-4 items-center">
        <p className="hidden text-sm leading-6 text-white md:inline">
          <strong className="font-semibold">Flick us some tokens!</strong>
          <svg
            viewBox="0 0 2 2"
            className="mx-2 inline h-0.5 w-0.5 fill-current"
            aria-hidden="true"
          >
            <circle cx={1} cy={1} r={1} />
          </svg>
          We&apos;re on the One Good Kiwi app this March
        </p>
        <p className="text-sm leading-6 text-white md:hidden">
          <strong className="font-semibold">Support us</strong> on One Good Kiwi
          this March
        </p>
        <Link
          href="/#one-good-kiwi"
          className="flex-none rounded-full bg-[#12CB77] px-3.5 py-1 text-sm font-semibold text-white shadow-sm hover:bg-navy-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-navy-900"
        >
          Details here <span aria-hidden="true">&rarr;</span>
        </Link>
      </div>
    </div>
  );
}

export function ChristmasAlertBanner() {
  return (
    <div className="relative isolate flex items-center justify-center gap-x-6 overflow-hidden bg-[#B3000C] px-6 py-2.5 sm:px-3.5">
      <div className="flex flex-wrap gap-y-2 gap-x-4 items-center">
        <p className="hidden text-sm leading-6 text-white md:inline">
          <strong className="font-semibold">
            Order our Christmas treats now!
          </strong>
          <svg
            viewBox="0 0 2 2"
            className="mx-2 inline h-0.5 w-0.5 fill-current"
            aria-hidden="true"
          >
            <circle cx={1} cy={1} r={1} />
          </svg>
          Caramilk balls, cookies, gingerbread and more...
        </p>
        <p className="text-sm leading-6 text-white md:hidden">
          Order our Christmas treats now!
        </p>
        <Link
          href="/shop"
          className="flex-none rounded-full bg-[#00b32c] px-3.5 py-1 text-sm font-semibold text-white shadow-sm hover:bg-[#1fd537] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#00b32c]"
        >
          Order here <span aria-hidden="true">&rarr;</span>
        </Link>
      </div>
    </div>
  );
}
